<template>
    <el-progress type="circle"  define-back-color="rgba(200,200,200,0.1)" :color="colors" :stroke-width="15" :width="100" :format="format" :percentage="percent"></el-progress>
</template>

<script>
export default {
  name: "memInfo",
  data() {
    return {
      percent:0,
      colors: [
        { color: "#409EFF", percentage: 30 },
        { color: "#67C23A", percentage: 40 },
        { color: "#E6A23C", percentage: 60 },
        { color: "#F56C6C", percentage: 80 },
        { color: "#FF0000", percentage: 90 },
      ],
    };
  },
  methods:{
    getInfo(data){
      this.percent = parseInt(data)
    },
    format(percentage){
        return 'Mem'+'\n'+percentage+'%'
    }
  }
};
</script>

<style scoped>
::v-deep .el-progress__text{
    white-space: pre; /*使换行符生效*/
    color:#474744 !important;
}
</style>