<template>
  <el-carousel height="10rem" direction="vertical" indicator-position="none">
    <el-carousel-item v-for="(disk, index) in diskInfo" :key="index">
      <el-row
        ><el-col class="disk_name"
          ><span>DiskName：{{ disk.name }}</span></el-col
        ></el-row
      >
      <el-row type="flex" justify="space-between"
        ><el-col
          ><el-row class="disk_IO_info_text">R:{{ disk.read }}/MB</el-row
          ><el-row class="disk_IO_info_text">W:{{ disk.write }}/MB</el-row></el-col
        ><el-col
          ><dv-percent-pond
            :config="disk.config"
            style="width: 100px; height: 70px" /></el-col
      ></el-row>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  name: "diskInfo",
  data() {
    return {
      diskInfo: [],
    };
  },
  methods: {
    getInfo(data) {
      this.diskInfo = data;
    },
  },
};
</script>

<style scoped>
.disk_name {
  text-align: center;
  margin: 1rem 0;
  color: #474744;
}
.disk_IO_info_text {
  margin-top: 0.5rem;
  margin-left: 2rem;
  font-size: 1rem;
  color: #474744;
}
</style>