<template>
  <div>
    <el-card class="box-card">
      <div>
        <el-row type="flex" :gutter="200" justify="center">
          <el-col :span="8"
            ><el-row class="data_title">用户数</el-row
            ><el-row class="data_number">{{ countOfUsers }}</el-row></el-col
          >
          <el-col :span="8"
            ><el-row class="data_title">分子数</el-row
            ><el-row class="data_number">{{ countOfMolecules }}</el-row></el-col
          >
          <el-col :span="8"
            ><el-row class="data_title">训练模型数</el-row
            ><el-row class="data_number">{{
              countOfNetworkModels
            }}</el-row></el-col
          >
        </el-row>
      </div>
    </el-card>
    <el-card class="hardware_resources_box-card">
      <div><HardwareResources /></div>
    </el-card>
  </div>
</template>

<script>
import HardwareResources from "./HardwareResources/index.vue";
export default {
  components: {
    HardwareResources,
  },
  data() {
    return {
      // 当前用户数
      countOfUsers: 0,
      // 当前审核通过的分子条目数
      countOfMolecules: 0,
      // 当前审核通过的网络模型数
      countOfNetworkModels: 0,
      //加载标志
      countOfUsersLoading: false,
      countOfMoleculesLoading: false,
      countOfNetworkModelsLoading: false,
    };
  },
  mounted() {
    this.getCountOfUsers();
    this.getCountOfModels();
    this.getCountOfNetworkModels();
  },
  methods: {
    getCountOfUsers() {
      this.countOfUsersLoading = true;
      this.$store
        .dispatch("dataDisplay/selectCountOfUsers")
        .then((res) => {
          const { data } = res;
          this.countOfUsers = data.count;
          this.countOfUsersLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.countOfUsersLoading = true;
        });
    },
    getCountOfModels() {
      this.countOfMoleculesLoading = true;
      this.$store
        .dispatch("dataDisplay/selectCountOfMolecules")
        .then((res) => {
          const { data } = res;
          this.countOfMolecules = data.count;
          this.countOfMoleculesLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.countOfMoleculesLoading = true;
        });
    },
    getCountOfNetworkModels() {
      this.countOfNetworkModelsLoading = true;
      this.$store
        .dispatch("dataDisplay/selectCountOfNetworkModels")
        .then((res) => {
          const { data } = res;
          this.countOfNetworkModels = data.count;
          this.countOfNetworkModelsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.countOfNetworkModelsLoading = true;
        });
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: electronicFont;
  src: url(../../assets/font/DS-Digital/DS-DIGI-1.ttf);
}
.data_title {
  text-align: center;
  font-size: 1.5rem;
}
.data_number {
  text-align: center;
  font-size: 3rem;
  color: rgb(19, 19, 19);
  font-family: electronicFont;
}
.box-card{
  margin-top: 4vh;
}
.hardware_resources_box-card{
  margin-top: 5vh;
  height: 60vh;
}
</style>