<template>
  <div class="echart" id="mychart"></div>
</template>
  
  <script>
import * as echarts from "echarts"; //引入 echarts
export default {
  name: "netInfo",
  data() {
    return {
      sent:0,
      recv:0,
      nowSent:0,
      nowRecv:0,
      sentVelList: [], //发送速度列表
      recvVelList: [], //接收速度列表
      myChart:null,
      option:null,
    };
  },
  watch: {
    sentVelList() {
      this.myChart.setOption(this.option); // 渲染页面
    },
  },
  mounted() {
    this.initEcharts();
  },
  methods: {
    //获取发送速度
    getSentVel(vel) {
      var temp = vel-this.sent;
      var m = parseInt(temp/1048576)
      if (this.sentVelList.length == 10) {
        this.sentVelList.shift();
        this.sentVelList.push(m);
      } else {
        this.sentVelList.push(m);
      }
      this.nowSent = m
      this.sent = vel
    },
    //获取接收速度
    getRecvVel(vel) {
      var temp = vel-this.recv;
      var m = parseInt(temp/1048576)
      if (this.recvVelList.length == 10) {
        this.recvVelList.shift();
        this.recvVelList.push(m);
      } else {
        this.recvVelList.push(m);
      }
      this.nowRecv = m
      this.recv = vel
    },
    initEcharts() {
    this.option = {
        legend: {
          data: [{
            name:"发送",textStyle:{color:"#474744"}
            
          },{
            name:"接收",textStyle:{color:"#474744"}
          }],
        },
        tooltip: {},
        xAxis: {
          type: "category",
          data: ["1s", "2s", "3s", "4s", "5s", "6s", "7s", "8s", "9s", "10s"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "发送",
            data: this.sentVelList,
            type: "line",
            smooth: true, //平滑曲线
          },
          {
            name: "接收",
            data: this.recvVelList,
            type: "line",
            smooth: true, //平滑曲线
          },
        ],
      };
      this.myChart = echarts.init(document.getElementById("mychart")); // 图标初始化
      //再次延时初始化图标以获取正常宽高
      setTimeout(()=>{
          this.myChart.resize();
        },500)  
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        setTimeout(()=>{
          this.myChart.resize();
        },500)  
      });
    },
  },
};
</script>
<style scoped>
.echart {
  width: 100%;
  height: 100%;
  color: #474744 !important;
}
</style>