<template>
  <div class="hardware-resources_info_box">
    <el-row type="flex" justify="space-between"
      ><el-col :span="6"
        ><div class="cpu_and_mem_info_box">
          <el-row type="flex" justify="space-between"
            ><el-col :span="8"
              ><cpuInfo class="cpu_info_box" ref="cpuinfo" /></el-col
            ><el-col :span="8"
              ><memInfo class="mem_info_box" ref="meminfo" /></el-col
          ></el-row>
          <el-row
            ><el-col
              ><div class="disk_info_box">
                <diskInfo ref="diskinfo" /></div></el-col
          ></el-row></div></el-col
      ><el-col :span="18"
        ><div class="net_info_box">
          <netInfo ref="netinfo" /></div></el-col
    ></el-row>
  </div>
</template>

<script>
import { getwebsocketURL } from "../../../util/websocket";
import cpuInfo from "./cpuInfo/index.vue";
import memInfo from "./memInfo/index.vue";
import diskInfo from "./diskInfo/index.vue";
import netInfo from "./netInfo/index.vue";
export default {
  name: "hardwareResources",
  components: {
    cpuInfo,
    memInfo,
    diskInfo,
    netInfo,
  },
  data() {
    return {
      ws: null,
      key: 0,
    };
  },
  mounted() {
    //屏幕适配方法
    this.pieOutlineFunc();
    setTimeout(() => {
      this.$refs.net_info_pannel.initWH();
    }, 100);

    this.getResourceInfo();
    window.onbeforeunload = (e) => {
      this.closeWS(); //调用自己的方法

      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return "关闭提示";
    };
  },
  methods: {
    //刷新页面关闭现有websocket链接
    closeWS() {
      this.ws.close();
    },
    getResourceInfo() {
      var wsURL = getwebsocketURL();
      this.ws = new WebSocket(wsURL);
      this.ws.onmessage = (res) => {
        var { data } = res;
        var hostInfo = JSON.parse(data);
        this.$refs.cpuinfo.getInfo(hostInfo.cpu_total_percent[0]);
        this.$refs.meminfo.getInfo(hostInfo.mem_used_percent);
        var diskList = [];
        hostInfo.disk_list.forEach((disk) => {
          if (disk.disk_usage != null) {
            diskList.push({
              name: disk.driver,
              write: parseInt(disk.disk_io.writeCount / 1048576),
              read: parseInt(disk.disk_io.readCount / 1048576),
              config: {
                value: parseInt(disk.disk_usage.usedPercent),
                localGradient: true,
                colors: ["#67C23A", "#F56C6C"],
              },
            });
          }
        });
        this.$refs.diskinfo.getInfo(diskList);
        this.$refs.netinfo.getSentVel(hostInfo.net_io[0].bytesSent);
        this.$refs.netinfo.getRecvVel(hostInfo.net_io[0].bytesRecv);
      };
    },
    //屏幕适配方法
    pieOutlineFunc() {
      var _this = this;
      window.addEventListener("pageshow", function (e) {
        _this.$nextTick(() => {
          _this.key++;
        });
      });
    },
  },
};
</script>

<style scoped>
.cpu_and_mem_info_box {
  padding-top: 2rem;
}
.cpu_info_box {
}
.mem_info_box {
}
.disk_info_box {
  margin-top: 15vh;
}
.net_info_box {
  margin-top: 2rem;
  width: 100%;
  height: 55vh;
}
</style>